import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import HowWorks from "../../components/coming-soon/HowWorks";
import WaitlistForm from "../../components/coming-soon/WaitlistForm";

const ComingSoon = () => {
  return (
    <>
      <Helmet>
        <title>
          Join waitlist now!
        </title>
      </Helmet>
      {/* End Page SEO Content */}
      <div className="full-height-layout d-flex align-items-center">
        <div className="coming-soon-content font-gordita">
          <div className="logo">
            <Link to="/">
              <img src="/images/logo/sagesacademy-logo-final-min.png" alt="brand logo" />
            </Link>
          </div>
          <div className="row justify-content-center">
            <HowWorks />
          </div>
            <div className="form-style-light">
              <h1 data-aos="fade-up">Join the waitlist now!</h1>
              <div style={{fontStyle: "italic", fontSize:"20px", marginTop: -50, marginBottom: 50}}>*Only 8 vacancies left for Evergreen students</div>
              <WaitlistForm />
            </div>

          <img
            src="images/shape/179.svg"
            alt="shape"
            className="shapes shape-one"
          />
          <img
            src="images/shape/182.svg"
            alt="shape"
            className="shapes shape-four"
          />
        
          <img
            src="images/shape/185.svg"
            alt="shape"
            className="shapes shape-seven"
          />
          
          <img
            src="images/shape/188.svg"
            alt="shape"
            className="shapes shape-ten"
          />
        </div>
        {/* /.coming-soon-content */}
      </div>
    </>
  );
};

export default ComingSoon;
