import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Routes from "./router/Routes";
import ScrollToTop from "./components/shared/ScrollToTop";
import AOS from "aos";
import "aos/dist/aos.css";

const App = () => {
  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);

  return (
    <>
      <Helmet titleTemplate="%s - SagesAcademy Student Care Singapore | Putting a little magic into all our kids." defaultTitle="SagesAcademy Student Care Singapore | Putting a little magic into all our kids.">
        <meta property="og:site_name" content="SagesAcademy Student Care Singapore" />
        <meta
          property="og:url"
          content="https://sagesacademy.sg"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="SagesAcademy Student Care Singapore | Putting a little magic into all our kids."
        />
        <meta
          name="keywords"
          content="student care, studentcare, enrichment, best tuition, Education, Woodlands, Admiralty, Teacher, Student"
        />
        <meta
          name="description"
          content="SagesAcademy is a top notch learning centre providing quality care and enrichment activities with a little magic to encourage developmental growth in a loving and secure environment."
        />

        <meta property="og:image" content="https://sagesacademy.sg/images/site/homepage/preview.png" />
      </Helmet>
      <ScrollToTop />
      <Routes />
    </>
  );
};

export default App;
