import React from "react";
import {useState} from 'react';

const WaitlistForm = () => {

    const [childName, setChildName] = useState('');
    const [school, setSchool] = useState('');
    const [level, setLevel] = useState('');
    const [contact, setContact] = useState('');
    const [referer, setReferer] = useState('');
    const [buttonDisabled, setButtonDisabled] = useState('');
    const [buttonText, setButtonText] = useState("Sign up");

    var [childNameInvalid, setChildNameInvalid] = useState(false);
    var [schoolInvalid, setSchoolInvalid] = useState(false);
    var [levelInvalid, setLevelInvalid] = useState(false);
    var [contactInvalid, setContactInvalid] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (event.target.childName.value.trim().length === 0){ childNameInvalid = true; setChildNameInvalid(true); } else { childNameInvalid = false; setChildNameInvalid(false); }
        if (event.target.school.value.trim().length === 0){ schoolInvalid = true; setSchoolInvalid(true); } else { schoolInvalid = false; setSchoolInvalid(false); }
        if (event.target.level.value.trim().length === 0){ levelInvalid = true; setLevelInvalid(true); } else { levelInvalid = false; setLevelInvalid(false); }
        if (event.target.contact.value.trim().length === 0){ contactInvalid = true; setContactInvalid(true); } else { contactInvalid = false; setContactInvalid(false); }

        console.log(schoolInvalid);
        if (childNameInvalid || schoolInvalid || levelInvalid || contactInvalid)
        {
            return false;
        }
            
        setButtonDisabled(true);
        setButtonText("Signing up...")
        
        const url = 'https://98tkzj42n4.execute-api.ap-southeast-1.amazonaws.com/subscribe'

        fetch(url, {
            method: 'POST',
            body: JSON.stringify({
                contact: event.target.contact.value,
                childName: event.target.childName.value,
                school: event.target.school.value,
                level: event.target.level.value,
                referer: event.target.referer.value
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        })
            .then((res) => res.json())
            .then((post) => {
                setButtonDisabled(true);
                setButtonText("Submitted!")
            })
            .catch((err) => {
                console.log(err.message);
                setButtonDisabled(false);
                setButtonText("Sign up")
                console.log(err)
            });
    }

  return (
    <form id="contact-form" onSubmit={handleSubmit}>
        <div className="row controls">
            <div className="col-md-4">
                <div className="input-group-meta form-group mb-40">
                    <label>Child's name</label>
                    <input
                    disabled={buttonDisabled}
                    type="text"
                    placeholder="Enter your child's name"
                    name="childName"
                    value={childName} 
                    onChange={event => setChildName(event.target.value)}
                    className={childNameInvalid ? "invalid" : ""}
                    />
                </div>
            </div>
            
            <div className="col-md-3">
                <div className="input-group-meta form-group mb-40">
                    <label>Mobile number</label>
                    <input
                    disabled={buttonDisabled}
                    type="text"
                    placeholder="Enter your mobile number"
                    name="contact"
                    value={contact} 
                    onChange={event => setContact(event.target.value)}
                    className={contactInvalid ? "invalid" : ""}
                    />
                </div>
            </div>
            <div className="col-md-5">
                <div className="input-group-meta form-group mb-40">
                    <label>Referred by (Optional)</label>
                    <input
                    disabled={buttonDisabled}
                    type="text"
                    placeholder="Enter name of parent/child who recommended"
                    name="referer"
                    value={referer} 
                    onChange={event => setReferer(event.target.value)}
                    />
                </div>
            </div>
        </div>
        <div className="row controls mb-40">
            <div className="col-md-6">
                <div className="input-group-meta form-group mb-40">
                    <label>School</label>
                    <input
                    disabled={buttonDisabled}
                    type="text"
                    placeholder="Enter your child's school"
                    name="school"
                    value={school} 
                    onChange={event => setSchool(event.target.value)}
                    className={schoolInvalid ? "invalid" : ""}
                    />
                </div>
            </div>
        {/* End .col */}
        <div className="col-md-3">
          <div className="input-group-meta form-group mb-40">
            <label>Level in 2025</label>
            <select name="level" onChange={event => setLevel(event.target.value)} disabled={buttonDisabled} className={levelInvalid ? "invalid" : ""}>
                <option value=""></option>
                <option value="K2">K2</option>
                <option value="Primary 1">Primary 1</option>
                <option value="Primary 2">Primary 2</option>
                <option value="Primary 3">Primary 3</option>
                <option value="Primary 4">Primary 4</option>
                <option value="Primary 5">Primary 5</option>
                <option value="Primary 6">Primary 6</option>
            </select>
          </div>
        </div>
        <div className="col-md-3">
            <button type="submit" disabled={buttonDisabled}>{buttonText}</button>
        </div>
      </div>
        {/* End .col */}
    </form>
  );
};

export default WaitlistForm;
