import { React, useEffect } from "react";

import ComingSoon from "../views/comingsoon/ComingSoon";

// Not Found Page
import NotFound from "../views/Misc/NotFound";

// Route Specific
import { BrowserRouter as Router, Route, Switch, useLocation } from "react-router-dom";
import ScrollTopBehaviour from "../components/shared/ScrollTopBehaviour";

export const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    window.gtag("event", "page_view", {
      page_path: location.pathname + location.search + location.hash,
      page_search: location.search,
      page_hash: location.hash,
    });
  }, [location]);
};

const Routes = () => {
  return (
    <>
      <Router>
        <ScrollTopBehaviour />
        <Switch>
          <Route exact path="/" component={ComingSoon} />
          {/* NotFound Route */}
          <Route component={NotFound} />
        </Switch>
      </Router>
    </>
  );
};

export default Routes;
