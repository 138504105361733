import React from "react";
import exam from "../../assets/images/coming-soon/exam.svg";
import spelling from "../../assets/images/coming-soon/spelling.svg";
import holdChild from "../../assets/images/coming-soon/hold-child.svg";
import food from "../../assets/images/coming-soon/food.svg";
import aircon from "../../assets/images/coming-soon/aircon.svg";
import guidance from "../../assets/images/coming-soon/guidance.svg";
import teacherStudent from "../../assets/images/coming-soon/teacher-student.svg";

const HowWorks = () => {
  const surveyContent = [
    {
      _id: 1,
      icon: guidance,
      title: "",
      descriptions: `Homework supervision & guidance`,
      bgColor: "#F8C0B9",
      delayAnimation: "0",
    },
    {
      _id: 2,
      icon: spelling,
      title: "",
      descriptions: `Daily testing of spelling & 听写 (TingXie)`,
      bgColor: "#BBEBD2",
      delayAnimation: "0",
    },
    {
      _id: 3,
      icon: exam,
      title: "",
      descriptions: `Practice test papers from top schools`,
      bgColor: "#B0DAEE",
      delayAnimation: "0",
    },
    {
      _id: 4,
      icon: teacherStudent,
      title: "",
      descriptions: `Low student-teacher ratio`,
      bgColor: "#F8C537",
      delayAnimation: "0",
    },
    {
      _id: 5,
      icon: food,
      title: "",
      descriptions: `Home-cooked food prepared in-house`,
      bgColor: "#F2F5D0",
      delayAnimation: "0",
    },
    {
      _id: 6,
      icon: aircon,
      title: "",
      descriptions: `Spacious and fully air-conditioned environment`,
      bgColor: "#F1FADE",
      delayAnimation: "0",
    },
    {
      _id: 7,
      icon: holdChild,
      title: "",
      descriptions: `Free fetching service from Evergreen Primary School`,
      bgColor: "#DDFDFE",
      delayAnimation: "0",
    }
  ];
  return (
    <>
      {surveyContent.map((survey) => (
        <div
          className="col-lg-3 col-md-6"
          data-aos="fade-up"
          data-aos-delay={survey.delayAnimation}
          key={survey._id}
        >
          <div className="block-style-thirtyFive text-center mt-30">
            <div
              className="icon d-flex align-items-center justify-content-center"
              style={{ background: survey.bgColor }}
            >
              <img src={survey.icon} alt="survey icon" />
            </div>
            <h4>{survey.title}</h4>
            <p>{survey.descriptions}</p>
          </div>
          {/* <!-- /.block-style-thirtyFive --> */}
        </div>
      ))}
    </>
  );
};

export default HowWorks;
